import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const DasHausDerAndacht = ({ data }) => (
    <InnerPage
        title="Das Haus der Andacht"
        description="Ein Ort tiefen Nachdenkens und gemeinsamen Handelns"
    >
        <IntroHolder
            title="Das Haus der Andacht"
            subtitle="Ein Ort tiefen Nachdenkens und gemeinsamen Handelns"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Gemeinsames Handeln',
                parent: 'Andacht und Dienst',
                'current-item': 'Das Haus der Andacht',
            }}
        >
            <IntroQuote>
                […] äußerlich betrachtet, ein stoffliches Gefüge ist, hat er
                geistige Wirkung. Er schmiedet Bande der Einheit von Herz zu
                Herz; er ist ein Sammelpunkt für die Menschenseelen...
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Im Haus der Andacht verwirklicht sich das Zusammenspiel von
                    Andacht und Dienst. Es wird von Bahá’u’lláh als „Aufgangsort
                    der Erwähnung Gottes“ bezeichnet und nimmt damit auch einen
                    zentralen Platz in der Gemeinschaft ein.
                </p>
                <div>
                    <p>
                        In Andacht und{' '}
                        <Link to="/woran-bahai-glauben/natur-des-menschen/andacht-gebet/">
                            Gebet
                        </Link>{' '}
                        wendet sich der Mensch Gott zu. Seinen vollendeten
                        Ausdruck findet das Gebet im Handeln und Dienst am
                        Nächsten. Die Bahá’í sind bestrebt, zusammen mit
                        Menschen aus ihrem Umfeld eine solche Haltung in der
                        Gesellschaft aufzubauen.
                    </p>
                    <p>
                        Dieser Geist spiegelt sich auch in der Bauweise der
                        Häuser der Andacht wider. Als einzigartiges Bauwerk, mit
                        neun Eingängen, steht dieser Ort für Menschen jeglicher
                        Herkunft oder Religion offen. In den Häusern der Andacht
                        wird aus den Schriften aller Religionen gelesen. Alle
                        sind eingeladen, sich im Haus der Andacht Gott
                        zuzuwenden als „Oase für tiefes Nachdenken über die
                        geistige Wirklichkeit und die grundlegenden Fragen des
                        Lebens, einschließlich der persönlichen und
                        gemeinschaftlichen Verantwortung für die Verbesserung
                        der Gesellschaft.”² Der Geist des Dienstes kann sich auf
                        vielfältige Weise zeigen, wobei sich die Umsetzung an
                        den jeweiligen Bedürfnissen orientiert. Im Schatten des
                        Hauses der Andacht bedeutet dies u.a. die Errichtung von
                        Zentren für Bildung und der sozialen Förderung.
                    </p>
                    <p>
                        Derzeit gibt es acht kontinentale Häuser der Andacht,
                        von denen das letzte dieser Reihe 2016 in Santiago de
                        Chile fertig gestellt wurde. An verschiedenen Orten in
                        der Welt werden auch lokale Häuser der Andacht
                        errichtet.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={data.collage.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        Das erste und bislang einzige Europäische Haus der
                        Andacht befindet sich in Hofheim-Langenhain im Taunus.
                        Dort gibt es eine Reihe von Veranstaltungen, die zum
                        Mitmachen einladen: an jedem Sonntag stattfindende
                        Andachten, in denen aus den Heiligen Schriften aller
                        Religionen gelesen wird, Aktivitäten für{' '}
                        <Link to="/gemeinsames-handeln/familie-und-kinder/kinderklasse/">
                            Kinder
                        </Link>
                        ,{' '}
                        <Link to="/gemeinsames-handeln/jugend/arbeit-mit-juniorjugendlichen/">
                            Jugendliche
                        </Link>
                        und{' '}
                        <Link to="/gemeinsames-handeln/gemeinsames-lernen/das-trainingsinstitut/">
                            Erwachsene
                        </Link>
                        , das jährlich stattfindende Sommerfest oder
                        verschiedene thematische Veranstaltungen im benachbarten
                        Nationalen Zentrum der{' '}
                        <Link to="/gemeinsames-handeln/die-bahai-in-deutschland/">
                            Bahá’í-Gemeinde in Deutschland
                        </Link>
                        .
                    </p>
                </div>
                <Reference>
                    <p>
                        1. ‘Abdu’l-Bahá, Briefe und Botschaften, 60:1 [2]
                        Botschaft des Universalen Hauses der Gerechtigkeit vom
                        18.12.2014
                    </p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default DasHausDerAndacht;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: { eq: "collage_Das-Haus-der-Andacht.jpg" }
        ) {
            ...fluidImage
        }
        collage: file(relativePath: { eq: "HausderAndacht.m.m.png" }) {
            ...fluidImage
        }
    }
`;
